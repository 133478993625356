import { Avatar, Stack, Typography } from '@mui/material';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share';

const ArticleAuthor = ({
  name,
  profile_image,
}: {
  name: string;
  profile_image: string;
}) => {
  const url = window.location.href;

  return (
    <Stack direction="column" gap={{ xs: 2, md: 5 }}>
      <Stack
        gap={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ mb: 1, height: 'fit-content' }}
        width={{ md: 'fit-content', sm: '100%' }}
      >
        <Avatar alt={name} sx={{ height: 50, width: 50 }} src={profile_image} />

        <Typography
          variant="body1"
          fontSize="18px"
          color="grey.800"
          fontWeight={500}
        >
          {name?.split(' ')[0]}
        </Typography>
      </Stack>

      <Stack>
        <Typography
          mb={2}
          fontSize="18px"
          variant="body1"
          color="grey.900"
          fontWeight={500}
          textAlign={{ xs: 'center', md: 'left' }}
        >
          Share This Post
        </Typography>

        <Stack
          width="100%"
          direction="row"
          flexWrap="wrap"
          gap={{ xs: 2, md: 1 }}
          justifyContent={{ md: 'flex-start', xs: 'center' }}
        >
          <LinkedinShareButton url={url} style={{ width: 'fit-content' }}>
            <LinkedinIcon size={32} bgStyle={{ fill: '#1d3f4c' }} round />
          </LinkedinShareButton>

          <TelegramShareButton url={url} style={{ width: 'fit-content' }}>
            <TelegramIcon size={32} bgStyle={{ fill: '#1d3f4c' }} round />
          </TelegramShareButton>

          <FacebookShareButton url={url} style={{ width: 'fit-content' }}>
            <FacebookIcon size={32} bgStyle={{ fill: '#1d3f4c' }} round />
          </FacebookShareButton>

          <TwitterShareButton url={url} style={{ width: 'fit-content' }}>
            <XIcon size={32} bgStyle={{ fill: '#1d3f4c' }} round />
          </TwitterShareButton>

          <RedditShareButton url={url} style={{ width: 'fit-content' }}>
            <RedditIcon size={32} bgStyle={{ fill: '#1d3f4c' }} round />
          </RedditShareButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ArticleAuthor;
