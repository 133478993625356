import { Box, Grid2 as Grid, Typography } from '@mui/material';

import globalIcon from '@icon/global.webp';
import security from '@icon/security.webp';
import customization from '@icon/customization.webp';

const VALUES = [
  {
    title: 'Security',
    description:
      'Protect your transactions with industry-leading security measures that ensure the safety and integrity of your payments.',
    icon: security,
  },
  {
    title: 'Global Reach',
    description:
      'Operate seamlessly across borders with our payment gateway, designed to support businesses worldwide.',
    icon: globalIcon,
  },
  {
    title: 'Customization',
    description:
      'Tailor our payment gateway to fit your brand and business needs with flexible customization options.',
    icon: customization,
  },
];

const Discover = () => {
  return (
    <Box py={{ lg: 17, xs: 12 }} px={2}>
      <Box
        textAlign="center"
        maxWidth={{ xs: 'none', md: '550px' }}
        margin="auto"
        mb={8}
      >
        <Typography variant="h4" fontWeight={600} gutterBottom>
          Effortless Crypto{' '}
          <Box component="span" sx={{ color: 'secondary.400' }}>
            Payments
          </Box>
        </Typography>
        <Typography>
          Discover the powerful solutions that make our cryptocurrency payment
          gateway the perfect solution for your business.
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'center' }}
        maxWidth={{ xs: 'none', md: '900px' }}
        margin="auto"
      >
        {VALUES.map(({ icon, title, description }) => (
          <Grid
            size={{ xs: 12, sm: 6, md: 4 }}
            key={title}
            textAlign="center"
            p={1}
            justifyContent="center"
          >
            <Box maxWidth="244px" margin="auto">
              <img src={icon} alt={title} width="42px" height="auto" />
              <Typography
                variant="body1"
                fontWeight={600}
                my={1.4}
                fontSize="20px"
              >
                {title}
              </Typography>
              <Typography>{description}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Discover;
