import React from 'react';
import { Box, Grid2 as Grid, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
  COMPANY_GROUP,
  CONTACT_GROUP,
  LEGAL_GROUP,
  RESOURCES_GROUP,
} from './constants';

const MenuGroup = ({ title, menuItems }: any) => {
  return (
    <Grid size="auto">
      <Typography variant="body1" mb={3} fontWeight={600}>
        {title}
      </Typography>

      <Stack gap={2}>
        {menuItems.map((item: any) => (
          <Link
            key={item.title}
            component={RouterLink}
            to={item.to}
            variant="body2"
            color="common.white"
            display="block"
            target={item.target}
            sx={{ textDecoration: 'none' }}
          >
            {item.title}
          </Link>
        ))}
      </Stack>
    </Grid>
  );
};

const TopSection: React.FC = () => {
  return (
    <Box>
      <Grid container spacing={4} justifyContent="space-between">
        <Grid size="auto">
          <img
            src="/logo.webp"
            alt="Company Logo"
            style={{ height: 'auto', width: 154, marginBottom: 24 }}
          />
          <Typography
            variant="body2"
            gutterBottom
            lineHeight="24px"
            maxWidth="300px"
          >
            ZinariPay is your all-in-one solution. Join us on the path to
            revolutionizing how you accept, send, and process payments today!
          </Typography>
        </Grid>

        <MenuGroup {...COMPANY_GROUP} />
        <MenuGroup {...RESOURCES_GROUP} />
        <MenuGroup {...LEGAL_GROUP} />
        <MenuGroup {...CONTACT_GROUP} />
      </Grid>
    </Box>
  );
};

export default TopSection;
