import { Box, Typography } from '@mui/material';

import CustomAccordion from '@component/Accordion';

const QUESTIONS = [
  {
    title: 'How much does it cost to integrate ZinariPay?',
    summary:
      'There are no setup costs. You only pay processing and withdrawal fees.',
  },
  {
    title: 'Can I track my transactions on the blockchain?',
    summary:
      'Yes, every transaction processed through our gateway includes a blockchain transaction ID, allowing you to track all your payments directly on the blockchain in real-time.',
  },
  {
    title: 'In what currency are my payments and withdrawals settled?',
    summary:
      'Payments and withdrawals are settled in the same cryptocurrency your customer paid in. We currently do not support currency conversion.',
  },
  {
    title: 'How often can I withdraw money from my account?',
    summary:
      'You can withdraw as many times as you like. However, standard withdrawal fees apply.',
  },
  {
    title: 'How long does it take for me to receive my money?',
    summary:
      'For USDT and USDC transfers, your funds typically arrive within a few minutes, though it can take up to an hour depending on network congestion.',
  },
  {
    title: 'Which cryptocurrencies does your gateway support?',
    summary:
      'Currently, our gateway supports USDT and USDC. We are continuously adding more cryptocurrencies to the list to meet your business needs.',
  },
  {
    title: 'How can I integrate with the product?',
    summary:
      'To integrate with ZinariPay, create an account. Once registered, follow our developer guide, complete the KYC process, and you’ll be ready to start receiving payments.',
  },
];

const CommonQuestions = () => {
  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { lg: 12.5, xs: 6 },
        background: 'rgba(223, 239, 249, 0.20)',
      }}
    >
      <Typography variant="h4" fontWeight={700} textAlign="center" mb={7}>
        Common Questions
      </Typography>

      <Box margin="auto" maxWidth={{ md: '700px', xs: 'none' }}>
        <CustomAccordion
          values={QUESTIONS}
          styles={{
            color: 'textSecondary',
            bgcolor: 'transparent',
            borderColor: 'secondary.50',
            border: 0,
          }}
        />
      </Box>
    </Box>
  );
};

export default CommonQuestions;
