import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import map from '@image/map.webp';

import { Article } from './index.interface';

const BlogCard = ({
  slug,
  title,
  cover_image,
  description,
  user,
  readable_publish_date,
  reading_time_minutes,
}: Article) => {
  const navigate = useNavigate();

  const goToArticle = () => {
    navigate(`/blog/${slug}`);
  };

  return (
    <Stack
      flexBasis={{ xs: '100%', sm: '48.45%', md: '32.3%' }}
      flexGrow={0}
      flexShrink={0}
      sx={{ cursor: 'pointer' }}
      gap={1}
      onClick={goToArticle}
    >
      <Box
        height={240}
        width="100%"
        borderRadius={4}
        border="1px solid"
        borderColor="grey.300"
        sx={{
          background: `url(${cover_image || map})`,
          backgroundSize: 'cover',
          backgroundPosition: 'left right',
        }}
      />

      <Typography variant="h5" fontWeight={500}>
        {title}
      </Typography>

      <Typography variant="body1" color="grey.800">
        {description}
      </Typography>

      <Stack direction="row" gap={1.4}>
        <Typography variant="caption" color="grey.8700">
          {user.name}
        </Typography>

        <Typography variant="caption" color="grey.8700">
          {readable_publish_date}
        </Typography>

        <Typography variant="caption" color="grey.8700">
          {reading_time_minutes} mins read
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BlogCard;
