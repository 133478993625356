import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import image from '@image/paylink.webp';

const PayLink = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        sx={{
          py: { xs: 15 },
          px: { lg: '100px', xs: 2 },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        gap={4}
      >
        <Box maxWidth="450px">
          <Typography variant="h4" fontWeight={600}>
            With Zinari
            <Box component="span" sx={{ color: 'secondary.400' }}>
              PayLink
            </Box>{' '}
            you get paid the way you prefer.
          </Typography>

          <Typography mt={4} mb="45px">
            We understand that not every business can afford a website or an
            engineering team. That&apos;s why we&apos;&apos;ve built Zinari
            PayLink—a simple way for businesses to collect payments in
            cryptocurrency without the need for technical expertise or coding
            knowledge.
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/paylink')}
          >
            Learn More
          </Button>
        </Box>

        <Box maxWidth="569px" sx={{ width: '100%' }}>
          <img src={image} alt="zinari paylink" width="100%" height="auto" />
        </Box>
      </Stack>
    </Box>
  );
};

export default PayLink;
