import React, { useEffect, useMemo, useState } from 'react';
import { Box, Chip, CircularProgress, Stack, Typography } from '@mui/material';

import policy from '@image/policy.webp';
import BlogCard from '@page/Blog/BlogCard';
import Helmet from '@component/Helmet';

import { Article } from './index.interface';

const Blog = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch('https://dev.to/api/articles?username=zinaritech')
      .then((res) => res.json())
      .then((articles) => {
        setArticles(articles);
      })
      .catch((e: any) => {
        console.error('Error fetching articles', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const tags = useMemo(() => {
    const tagSet = new Set();
    articles?.map((article: Article) => {
      article.tag_list.map((tag: string) => tagSet.add(tag));
    });
    return Array.from(tagSet.keys()) as string[];
  }, [articles]);

  return (
    <Box sx={{ width: '100%' }}>
      <Helmet
        title="Stay up to Date on Fintech in Africa with the ZinariPay Blog"
        description="Discover integration guides, news, and expert insights on cryptocurrency, fintech, and finance in Africa. Stay informed on the trends shaping Africa's digital economy with ZinariPay."
        keywords="zinaripay, blog, fintech, integration guides, cryptocurrency, africa, finance, news, article"
      />

      <Box>
        <Stack
          sx={{
            px: 2,
            mt: 8,
            width: '100%',
            alignItems: 'center',
            backgroundSize: 'cover',
            justifyContent: 'center',
            height: { md: '390px', xs: '280px' },
            background: `url(${policy}) no-repeat top right`,
          }}
        >
          <Box textAlign="center" maxWidth={{ md: '909px', xs: 'none' }}>
            <Typography
              variant="h2"
              color="common.white"
              fontWeight={700}
              mb={3}
              fontSize={{ xs: '24px', md: '60px' }}
            >
              Zinari Blog
            </Typography>
            <Typography color="common.white">Stay up to date</Typography>
          </Box>
        </Stack>
      </Box>

      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="60vh"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack gap={3} sx={{ px: { lg: '100px', xs: 2 } }} py={7}>
          {articles.length && (
            <>
              <Typography variant="h4" fontWeight={500}>
                Most Recent
              </Typography>

              <Stack direction="row" flexWrap="wrap" gap={1}>
                {tags?.map((tag) => (
                  <Chip
                    sx={{ bgcolor: 'secondary.50' }}
                    key={tag}
                    label={tag}
                  />
                ))}
              </Stack>
            </>
          )}

          <Stack
            flexWrap="wrap"
            direction="row"
            rowGap={{ xs: 3, md: 8 }}
            justifyContent="space-between"
          >
            {articles.map((article) => (
              <BlogCard {...article} key={article.id} />
            ))}
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default Blog;
