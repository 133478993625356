import { Button, Chip, Stack, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import ArticleAuthor from '@page/Blog/ArticleAuthor';

import { Article } from './index.interface';

const ArticleBody = ({
  tags,
  user,
  title,
  cover_image,
  body_markdown,
  readable_publish_date,
  reading_time_minutes,
}: Article) => {
  const navigate = useNavigate();

  const goToBlogs = () => {
    navigate(`/blog`);
  };

  useEffect(() => {
    if (!title) return;

    Array.from(document.querySelectorAll('.BlogArticle__body a')).map(
      (link: Element) => {
        link.setAttribute('target', '_blank');
      },
    );
  }, [title]);

  return (
    <Stack
      sx={{ px: { lg: '100px', xs: 2 } }}
      mt={8}
      py={7}
      alignItems="center"
      direction="column"
    >
      <Stack maxWidth="1040px" width="100%" gap={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={3}
          flexWrap="wrap"
        >
          <Button
            startIcon={<ChevronLeft />}
            onClick={goToBlogs}
            sx={{ minWidth: '100px' }}
          >
            Go Back
          </Button>

          <Stack direction="row" flexWrap="wrap" gap={1}>
            {Array.isArray(tags) &&
              tags?.map((tag) => (
                <Chip sx={{ bgcolor: 'secondary.50' }} key={tag} label={tag} />
              ))}
          </Stack>
        </Stack>
        <Typography variant="h4" fontWeight={600} textAlign="center">
          {title}
        </Typography>{' '}
        <Typography variant="caption" color="grey.800" textAlign="center">
          {readable_publish_date} ({reading_time_minutes} mins read)
        </Typography>
        {cover_image && (
          <img
            src={cover_image}
            alt={title}
            style={{
              maxWidth: 1040,
              width: '100%',
            }}
          />
        )}
        <Stack
          direction={{
            md: 'row',
            xs: 'column',
          }}
          gap={{ md: 10, xs: 2 }}
        >
          <ArticleAuthor
            name={user?.name}
            profile_image={user?.profile_image}
          />

          <div className="BlogArticle__body">
            <Markdown>{body_markdown}</Markdown>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ArticleBody;
