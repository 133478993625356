import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import paylink from '@image/paylink.webp';
import { CONSOLE_URL } from '@variable';

const Hero = () => {
  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      gap={2}
      sx={{
        height: { lg: '100vh', xs: 'fit-content' },
        maxHeight: { lg: '550px', xs: 'none' },
        mb: 10,
      }}
    >
      <Box
        sx={{
          pr: 2,
          pl: { lg: '100px', xs: 2 },
          maxWidth: { md: '610px', xs: 'none' },
        }}
      >
        <Typography variant="h2" fontWeight={700} textAlign="left" mb={5}>
          Get{' '}
          <Box component="span" sx={{ color: 'secondary.400' }}>
            paid
          </Box>{' '}
          the way you prefer.
        </Typography>
        <Typography lineHeight="27px" mb={5}>
          We understand that not every business can afford a website or an
          engineering team. That&apos;s why we&apos;ve built Zinari PayLink—a
          simple way for businesses to collect payments in cryptocurrency
          without the need for technical expertise or coding knowledge.
        </Typography>
        <Stack direction="row" gap={4}>
          <Button
            variant="contained"
            color="primary"
            href={CONSOLE_URL}
            target="_blank"
          >
            Get Started
          </Button>
          <Button
            variant="outlined"
            color="primary"
            href="https://www.youtube.com/watch?v=lDqkzNohwS0"
            target="_blank"
          >
            View Live Demo
          </Button>
        </Stack>
      </Box>

      <Box
        width="fill-available"
        sx={{
          px: { xs: 2, lg: 0 },
          background: `url(${paylink}) no-repeat center center`,
          backgroundSize: 'contain',
          height: { xs: '300px', lg: '480px' },
        }}
      />
    </Stack>
  );
};

export default Hero;
