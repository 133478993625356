import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import scan from '@image/scan.webp';

const Scan = () => {
  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { lg: 15, xs: 5 },
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
      >
        <Box maxWidth={{ md: '577px', xs: 'none' }}>
          <Typography variant="h4" fontWeight={600}>
            Enhance customer satisfaction with a smooth payment experience.
          </Typography>
          <Typography variant="body1" mt={3} mb={4}>
            ZinariPay offers a smooth payment experience that delights
            customers. With our intuitive interface and fast transaction
            processes, customers can easily make payments, enhancing
            satisfaction and loyalty. Trust ZinariPay to elevate your payment
            interactions and keep customers coming back.
          </Typography>
        </Box>

        <Box maxWidth={{ md: '551px', xs: 'none' }}>
          <img src={scan} alt="sale lock" width="100%" height="auto" />
        </Box>
      </Stack>
    </Box>
  );
};

export default Scan;
