import { Box, Button, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import background from '@image/developer-back.webp';
import developer from '@image/developer.webp';

const TITLE = 'For Developers';

const Developer = ({
  title = TITLE,
  description,
  image,
}: {
  title?: string;
  description?: any;
  image?: any;
}) => {
  return (
    <Box
      sx={{
        background: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center right',
        height: { xs: 'fit-content' },
        maxHeight: { lg: '900px', xs: 'none' },
        py: 12.5,
        px: { lg: '100px', xs: 2 },
      }}
    >
      <Box>
        <Typography variant="body1" color="common.white" fontSize="18px">
          {title}
        </Typography>

        {!description ? (
          <Typography
            variant="h4"
            color="common.white"
            fontWeight={500}
            maxWidth="485px"
            mt={4}
            mb={9}
            lineHeight="44px"
          >
            An All-in-One API Suite for Crafting Unmatched{' '}
            <Box component="span" sx={{ color: 'secondary.400' }}>
              Payment
            </Box>{' '}
            Experiences
          </Typography>
        ) : (
          description
        )}

        {!image ? (
          <Box
            sx={{
              background: `url(${developer})`,
              backgroundSize: 'cover',
              backgroundPosition: { md: 'center right', xs: 'top left' },
              width: '100%',
              height: { md: '460px', xs: 'fit-content' },
              borderRadius: '20px',
              py: 6,
              px: { xs: 2, md: 7 },
              pr: { xs: 2, md: 75 },
            }}
          >
            <Typography
              variant="h4"
              maxWidth="530px"
              fontWeight={500}
              color="common.white"
              mb={4}
            >
              Easily integrate seamless and powerful payment solutions into your
              platform
            </Typography>
            <Button
              sx={{
                color: 'common.white',
                p: 0,
              }}
              variant="text"
              endIcon={<ChevronRight color="inherit" />}
              href="https://api-docs.zinari.io/"
              target="_blank"
            >
              Read our API Docs
            </Button>
          </Box>
        ) : (
          image
        )}
      </Box>
    </Box>
  );
};

export default Developer;
