import { Box, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { FC, useState } from 'react';

import TabPanel from './TabPanel';

const TABS = ['Cryptocurrencies'];

const Header: FC<{ value: number }> = ({ value }) => {
  const text =
    value === 1
      ? 'These are the cryptocurrencies your customers can use to make payments on your app. Please note that all settlements will be made in the cryptocurrency, and no fiat currencies will be paid out.'
      : 'These are the countries (base fiat currencies) you can set for your app. When a payment is made, we automatically convert the fiat amount to the corresponding crypto value e.g. USDT, for the transaction. However, all payouts will be in the cryptocurrency.';

  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: { md: '530px', xs: 'none' },
      }}
    >
      <Typography variant="h4" mb={3} fontWeight={500}>
        Supported {value === 0 ? 'Regions' : 'Cryptocurrencies'}
      </Typography>
      <Typography variant="body1" mb={3} fontWeight={500} color="textSecondary">
        {text}
      </Typography>
    </Box>
  );
};

const CRYPTOCURRENCIES = [
  {
    name: 'USDT',
  },
  {
    name: 'USDC',
  },
  {
    name: 'Ethereum',
    isComingSoon: true,
  },
  {
    name: 'Litecoin',
    isComingSoon: true,
  },
];

const FIAT_CURRENCIES = [
  {
    name: 'Nigeria',
  },
  {
    name: 'South Africa',
  },
  { name: 'Kenya' },
  // { name: 'Ghana' },
  {
    name: 'United States',
  },
  {
    name: 'Canada',
  },
  {
    name: 'Europe',
  },
  {
    name: 'Britain',
  },
  {
    name: 'India',
  },
  {
    name: 'United Arab Emirates',
  },
];

const TableTitle = () => {
  return (
    <>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        py={3}
        px={1}
      >
        <Typography
          fontWeight={900}
          variant="h3"
          lineHeight="32px"
          textAlign="left"
        >
          -
        </Typography>

        <Typography
          fontWeight={700}
          color="textSecondary"
          variant="body1"
          fontSize="20px"
          textAlign="right"
        ></Typography>
      </Stack>
      <Divider sx={{ borderColor: 'primary.200' }} />
    </>
  );
};

const TableItem: FC<{ name: string; isComingSoon?: boolean }> = ({
  name,
  isComingSoon,
}) => {
  return (
    <>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        py={3}
        px={1}
      >
        <Stack gap={2} alignItems="center" direction="row">
          <img
            src={require(`assets/icons/${name
              .toLowerCase()
              .replace(/\s/g, '-')}.webp`)}
            alt=""
            width={32}
            height={32}
          />
          <Typography textAlign="left">{name}</Typography>
        </Stack>

        {isComingSoon ? (
          <Box
            p={1}
            py={0.5}
            bgcolor="secondary.400"
            color="common.white"
            borderRadius="10px"
          >
            <Typography variant="body2" fontWeight={600} textAlign="right">
              Coming soon
            </Typography>
          </Box>
        ) : (
          <Typography color="textSecondary" textAlign="right">
            -
          </Typography>
        )}
      </Stack>
      <Divider sx={{ borderColor: 'primary.200' }} />
    </>
  );
};

const Cryptocurrencies: FC<{
  value: number;
  index: number;
}> = ({ value, index }) => {
  return (
    <TabPanel value={value} index={index}>
      <TableTitle />
      {CRYPTOCURRENCIES.map((crypto) => (
        <TableItem {...crypto} key={crypto.name} />
      ))}
    </TabPanel>
  );
};

const FiatCurrencies: FC<{
  value: number;
  index: number;
}> = ({ value, index }) => {
  return (
    <TabPanel value={value} index={index}>
      <TableTitle />
      {FIAT_CURRENCIES.map((crypto) => (
        <TableItem {...crypto} key={crypto.name} />
      ))}
    </TabPanel>
  );
};

const Table: FC<{
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}> = ({ onChange, value }) => {
  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: { md: '847px', xs: 'none' },
      }}
    >
      <Tabs
        centered
        value={value}
        onChange={onChange}
        aria-label="supported currencies tabs"
        textColor="primary"
        indicatorColor="secondary"
      >
        {TABS.map((tab) => (
          <Tab
            label={tab}
            key={tab}
            sx={{ textTransform: 'capitalize', fontSize: '18px' }}
          />
        ))}
      </Tabs>
      <Cryptocurrencies index={1} value={value} />
      <FiatCurrencies index={0} value={value} />
    </Box>
  );
};

const SupportedCurrencies: FC = () => {
  const [value, setValue] = useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        py: { lg: 15, xs: 5 },
        px: { lg: '100px', xs: 2 },
        background: 'rgba(223, 239, 249, 0.20)',
      }}
    >
      <Header value={value} />
      <Table value={value} onChange={handleChange} />
    </Box>
  );
};

export default SupportedCurrencies;
