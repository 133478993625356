import {
  Box,
  Card,
  CardContent,
  Grid2 as Grid,
  Typography,
} from '@mui/material';

import background from '@image/developer-back.webp';
import platform from '@icon/platform.webp';
import wallet from '@icon/wallet.webp';
import cash from '@icon/cash.webp';
import nocode from '@icon/nocode.webp';

const VALUES = [
  {
    title: 'Easy Integration',
    description:
      'Seamlessly integrate with your existing platforms using our flexible CDN, NPM package, APIs and Webhooks.',
    icon: platform,
  },
  {
    title: 'Crypto Payout',
    description:
      'Enjoy the benefits of fast, secure, and global transactions with our cryptocurrency payout options. Receive payments directly in popular cryptocurrencies.',
    icon: wallet,
  },
  {
    title: 'Multi-Currency Support',
    description:
      'Expand your reach by accepting payments in multiple cryptocurrencies and fiat currencies, all within a single platform.',
    icon: cash,
  },
  {
    title: 'No Coding Required',
    description:
      'Zinari PayLink allows businesses to accept crypto payments without coding or technical skills.',
    icon: nocode,
  },
];

const Features = () => {
  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { lg: 15, xs: 5 },
        background: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center right',
      }}
    >
      <Grid container spacing={6}>
        {VALUES.map(({ title, description, icon }) => (
          <Grid size={{ xs: 12, md: 6 }} key={title}>
            <Card
              sx={{
                height: '100%',
                borderLeft: '5px solid ',
                borderColor: 'secondary.400',
                bgcolor: 'common.white',
              }}
            >
              <CardContent
                sx={{
                  pt: { xs: 2, md: 3 },
                  pb: { xs: 3, md: 4 },
                  pl: { xs: 4, md: 7 },
                  pr: { xs: 5, md: 8 },
                }}
              >
                <img
                  src={icon}
                  alt="ZinariPay features"
                  height="auto"
                  width="40px"
                />
                <Typography variant="h5" pt={3} pb={2} fontWeight={500}>
                  {title}
                </Typography>
                <Typography>{description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;
