import { Box } from '@mui/material';

import CallToAction from '@component/CallToAction';
import Globally from '@component/Globally';
import Helmet from '@component/Helmet';

import Hero from './components/Hero';
import Discover from './components/Discover';
import Features from './components/Features';
import Grow from './components/Grow';
import SupportedCurrencies from './components/SupportedCurrencies';

const Solutions = () => {
  return (
    <Box pt={{ md: 19, xs: 12 }}>
      <Helmet
        title="Solutions"
        description="ZinariPay supports Nigeria, South Africa, kenya, ghana, united states, canadam europe, britain, india, United arab emirates"
        keywords="zinari, zinari coin, zina, zinapay, zinaricoin, zinarifinance, zinaricoin, zinari finance, zinari payment, zinari nigeria, zinari corp, zinari technologies nigeria limited, ZinariPay solutions, features, cryptocurrency payments, secure payments, blockchain, crypto payouts, integration, fintech, digital payments"
      />
      <Hero />
      <Discover />
      <Features />
      <Grow />
      <SupportedCurrencies />
      <Globally />
      <CallToAction
        title="Ready to get started?"
        description="Join the thousands of businesses already using ZinariPay It's free to sign up."
        showSalesBtn={false}
      />
    </Box>
  );
};

export default Solutions;
