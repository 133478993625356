import './BlogArticle.scss';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import Helmet from '@component/Helmet';
import ArticleBody from '@page/Blog/ArticleBody';

import { Article } from './index.interface';

const BlogArticle = () => {
  const [article, setArticle] = useState<Article>();
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://dev.to/api/articles/zinaritech/${slug}`)
      .then((res) => res.json())
      .then((article) => {
        setArticle(article);
      })
      .catch((e: any) => {
        console.error('Error fetching article', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Helmet
        title={article?.title}
        description={article?.description}
        keywords={article?.tag_list as unknown as string}
      />

      {isLoading && !article ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="60vh"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <ArticleBody {...((article || {}) as any)} />
      )}
    </Box>
  );
};

export default BlogArticle;
