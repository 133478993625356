import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import support from '@image/support.webp';
import mail from '@icon/mail.webp';
import useTawkTo from '@hook/useTawkTo.hook';

const Hero = () => {
  const { maximize } = useTawkTo();

  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      gap={2}
      sx={{
        height: { lg: '100vh', xs: 'fit-content' },
        maxHeight: { lg: '550px', xs: 'none' },
      }}
      mb={{ md: 15, xs: 5 }}
    >
      <Box
        sx={{
          pr: 2,
          pl: { lg: '100px', xs: 2 },
          maxWidth: { md: '730px', xs: 'none' },
        }}
      >
        <Typography variant="body1" color="textSecondary" mb={3}>
          Contact Us
        </Typography>
        <Typography variant="h2" fontWeight={700} textAlign="left" mb={2}>
          We&apos;re here to support you every step of the way.
        </Typography>
        <Typography lineHeight="27px" mb={10}>
          Support Hours: 24 hours a day, Mon to Fri
        </Typography>
        <Stack direction={{ md: 'row', xs: 'column' }} gap={4}>
          <Button
            size="large"
            color="primary"
            onClick={maximize}
            variant="contained"
          >
            Chat with support
          </Button>
          <Button
            size="large"
            variant="text"
            color="secondary"
            startIcon={
              <img src={mail} width="32px" height="auto" alt="mail ZinariPay" />
            }
            href="mailto:support@zinari.io"
            sx={{
              color: 'primary.900',
              textTransform: 'none',
              textUnderlineOffset: '4px',
              textDecoration: 'underline',
            }}
          >
            support@zinari.io
          </Button>
        </Stack>
      </Box>

      <Box
        width="fill-available"
        sx={{
          px: { xs: 2, lg: 0 },
          background: `url(${support}) no-repeat center center`,
          backgroundSize: 'contain',
          height: { xs: '300px', lg: '480px' },
          mt: { xs: 7 },
        }}
      />
    </Stack>
  );
};

export default Hero;
