import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import Fees from './Fees';

const Hero = () => {
  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { md: 20, xs: 10 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { md: 'flex-start', xs: 'center' },
        justifyContent: 'space-between',
        gap: 9,
      }}
    >
      <Stack justifyContent="flex-start">
        <Typography
          variant="h2"
          fontWeight={700}
          sx={{ margin: 'auto', px: 1, mb: 4 }}
          maxWidth={{ md: '800px', xs: 'none' }}
        >
          Simple Pricing built
          <br />
          for businesses of
          <br />
          all sizes
        </Typography>

        <Typography
          textAlign="left"
          fontWeight="bold"
          fontStyle="italic"
          variant="body2"
          sx={{ px: 1, mb: 1 }}
          maxWidth={{ md: '550px', xs: 'none' }}
          color="textSecondary"
        >
          You’ll never pay less than $1 per transaction, even on small payments.
        </Typography>
        <Typography
          textAlign="left"
          variant="body2"
          fontWeight="bold"
          fontStyle="italic"
          sx={{ px: 1, mb: 1 }}
          maxWidth={{ md: '550px', xs: 'none' }}
          color="textSecondary"
        >
          You’ll never pay more than $5, no matter how large the transaction.
        </Typography>
        <Typography
          textAlign="left"
          sx={{ px: 1, mb: 1 }}
          maxWidth={{ md: '550px', xs: 'none' }}
          color="textSecondary"
        >
          ZinariPay charges a simple, transparent 1% transaction fee <br />
          on every payment processed through our platform. To keep
          <br /> things fair for businesses of all sizes, we’ve introduced caps
        </Typography>
      </Stack>
      <Box maxWidth={{ lg: '538px', xs: '450px' }} width="100%">
        <Fees fee="1%" description="On all transactions" />
      </Box>
    </Box>
  );
};

export default Hero;
