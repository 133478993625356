import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import useTawkTo from '@hook/useTawkTo.hook';
import { CONSOLE_URL } from '@variable';

const TITLE = 'Ready to Revolutionize Your Payments?';
const DESCRIPTION =
  'ZinariPay is here to offer an entirely new approach, empowering businesses and individuals alike to manage cryptocurrency payments effortlessly.';

const CallToAction = ({
  title = TITLE,
  description = DESCRIPTION,
  showSalesBtn = true,
}: {
  title?: string;
  description?: string;
  showSalesBtn?: boolean;
}) => {
  const { maximize } = useTawkTo();

  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { lg: 15, xs: 5 },
      }}
    >
      <Stack
        sx={{
          background: 'rgba(223, 239, 249, 0.20)',
          maxWidth: { sx: '100%', lg: '980px' },
          margin: 'auto',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          p: { md: 8, sm: 2 },
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body1" maxWidth="620px" mt={3} mb={6}>
          {description}
        </Typography>

        <Stack direction="row" gap={3}>
          <Button
            variant="contained"
            color="primary"
            href={CONSOLE_URL}
            target="_blank"
          >
            Get Started
          </Button>
          {showSalesBtn && (
            <Button
              variant="outlined"
              color="primary"
              onClick={maximize}
              href="mailto:sales@zinari.io"
            >
              Contact Sales
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CallToAction;
